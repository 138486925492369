const adapterColumnsAdSpaces = (columns) => {
  const columsFilters = [];
  columns.split(",").forEach((col) => {
    switch (col) {
      case "id":
        columsFilters.push("id");
        break;
      case "title":
        columsFilters.push("title");
        break;
      case "asset":
        columsFilters.push("asset");
        break;
      case "city":
        columsFilters.push("city");
        break;
      case "type":
        columsFilters.push("adspace_type");
        break;
      case "price":
        columsFilters.push("display_price");
        break;

      default:
        break;
    }
  });
  return columsFilters.join(",");
};
const adapterColumnsSpaces = (columns) => {
  const columsFilters = [];
  columns.split(",").forEach((col) => {
    switch (col) {
      case "id":
        columsFilters.push("id");
        break;
      case "title":
        columsFilters.push("title");
        break;
      case "asset":
        columsFilters.push("asset_name");
        break;
      case "city":
        columsFilters.push("city");
        break;

      case "location":
        columsFilters.push("subtype_name");
        break;
      case "type":
        columsFilters.push("type_name");
        break;
      case "size":
        columsFilters.push("size");
        break;
      case "price":
        columsFilters.push("display_price");
        break;
      default:
        break;
    }
  });
  return columsFilters.join(",");
};
const adapterColumnsInvoiceByUser = (columns) => {
  const columsFilters = [];
  columns.split(",").forEach((col) => {
    switch (col) {
      case "id":
        columsFilters.push("id");
        break;
      case "number":
        columsFilters.push("number");
        break;
      case "booking_id":
        columsFilters.push("booking_id");
        break;
      case "date":
        columsFilters.push("date");
        break;
      case "asset_name":
        columsFilters.push("asset");
        break;
      case "brand_name":
        columsFilters.push("brand_name");
        break;
      case "company":
        columsFilters.push("company");
        break;
      case "tax_id":
        columsFilters.push("tax_id");
        break;
      case "price":
        columsFilters.push("net_total");
        break;
      case "booking_category":
        columsFilters.push("booking_category_slug");
        break;
      case "confirmed_at":
        columsFilters.push("status");
        break;
      case "property_code":
        columsFilters.push("property_code");
        break;
      case "lease_id":
        columsFilters.push("lease_id");
        break;
      case "category_income_code":
        columsFilters.push("category_income_code");
        break;
      case "category_income_name":
        columsFilters.push("category_income_name");
        break;
      default:
        break;
    }
  });
  return columsFilters.join(",");
};

const adapterColumnsInvoicesbyyear = (columns) => {
  const columsFilters = [];
  columns.split(",").forEach((col) => {
    switch (col) {
      case "id":
        columsFilters.push("id");
        break;
      case "asset":
        columsFilters.push("asset_name");
        break;
      case "brand":
        columsFilters.push("brand_name");
        break;
      case "company":
        columsFilters.push("company_name");
        break;
      case "category":
        columsFilters.push("booking_category_slug");
        break;
      case "start":
        columsFilters.push("start");
        break;
      case "end":
        columsFilters.push("end");
        break;
      case "taxId":
        columsFilters.push("company_tax_id");
        break;
      case "duration":
        columsFilters.push("days_count");
        break;
      case "space":
        columsFilters.push("space");
        break;
      default:
        break;
    }
  });
  return columsFilters.join(",");
};

const adapterColumnsBookings = (columns) => {
  const columsFilters = [];
  columns.split(",").forEach((col) => {
    switch (col) {
      case "id":
        columsFilters.push("id");
        break;
      case "created_at":
        columsFilters.push("created_at");
        break;
      case "state":
        columsFilters.push("status");
        break;
      case "start":
        columsFilters.push("start");
        break;
      case "end":
        columsFilters.push("end");
        break;
      case "daycount":
        columsFilters.push("days_count");
        break;
      case "price":
        columsFilters.push("price");
        break;
      case "agent":
        columsFilters.push("agent_name");
        break;
      case "client":
        columsFilters.push("author_name");
        break;
      case "company":
        columsFilters.push("company_name");
        break;
      case "brand":
        columsFilters.push("brand_name");
        break;
      case "project":
        columsFilters.push("project_name");
        break;
      case "asset":
        columsFilters.push("asset_name");
        break;
      case "spaceName":
        columsFilters.push("space");
        break;
      default:
        break;
    }
  });
  return columsFilters.join(",");
};
const adapterColumnsAssets = (columns) => {
  const columsFilters = [];
  columns.split(",").forEach((col) => {
    switch (col) {
      case "id":
        columsFilters.push("id");
        break;
      case "name":
        columsFilters.push("name");
        break;
      case "city":
        columsFilters.push("city");
        break;
      case "country":
        columsFilters.push("country");
        break;
      case "address":
        columsFilters.push("address");
        break;
      case "taxId":
        columsFilters.push("tax_id");
        break;
      default:
        break;
    }
  });
  return columsFilters.join(",");
};
const adapterColumnsLandlords = (columns) => {
  const columsFilters = [];
  columns.split(",").forEach((col) => {
    switch (col) {
      case "id":
        columsFilters.push("id");
        break;
      case "name":
        columsFilters.push("name");
        break;
      case "country":
        columsFilters.push("country");
        break;
      case "address":
        columsFilters.push("address");
        break;
      case "taxId":
        columsFilters.push("tax_id");
        break;
      default:
        break;
    }
  });
  return columsFilters.join(",");
};
const adapterColumnsUsers = (columns) => {
  const columsFilters = [];
  columns.split(",").forEach((col) => {
    switch (col) {
      case "name":
        columsFilters.push("name");
        columsFilters.push("lastname");
        break;
      case "email":
        columsFilters.push("email");
        break;
      case "lastLoginDate":
        columsFilters.push("last_login_time");
        break;
      case "role":
        columsFilters.push("role");
        break;
      case "propertyIdsAccess":
        columsFilters.push("managed_assets");
        break;
      default:
        break;
    }
  });
  return columsFilters.join(",");
};
const adapterColumnsCompanies = (columns) => {
  const columsFilters = [];
  columns.split(",").forEach((col) => {
    switch (col) {
      case "id":
        columsFilters.push("id");
        break;
      case "name":
        columsFilters.push("name");
        break;
      case "country":
        columsFilters.push("country");
        break;
      case "taxId":
        columsFilters.push("tax_id");
        break;
      case "billing_address":
        columsFilters.push("billing_address");
        break;
      case "isAgency":
        columsFilters.push("is_agency");
        break;
      default:
        break;
    }
  });
  return columsFilters.join(",");
};

export {
  adapterColumnsAdSpaces,
  adapterColumnsSpaces,
  adapterColumnsInvoiceByUser,
  adapterColumnsInvoicesbyyear,
  adapterColumnsBookings,
  adapterColumnsAssets,
  adapterColumnsLandlords,
  adapterColumnsUsers,
  adapterColumnsCompanies,
};
